import React, { useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { produce } from 'immer'

import Loading from 'piconetworks/pkg-loading'

const mapSeries = async (
    series = [],
    mapper = () => { },
    initialPromise,
) => {
    initialPromise = initialPromise || new Promise((resolve) => resolve([]))

    const seriesResults = await series.reduce(async (acc, value, index, array) => {
        try {
            const results = await acc

            const arrayLength = array.length
            const valueIsFunction = value && {}.toString.call(value) === '[object Function]'

            const mapperResults = await mapper(value, index, arrayLength)

            const result = (valueIsFunction) ? await value(mapperResults) : mapperResults
            results.push(result)

            acc = new Promise((resolve) => resolve(results))

            return acc
        } catch (error) {
            error.details = {
                results: await acc,
                value,
                index,
                array,
            }

            throw error
        }
    }, initialPromise)

    return seriesResults
}

const actions = {
    CHANGE_COMPLETION_STATUS: 'CHANGE_COMPLETION_STATUS',
}

const init = (conditions) => {
    const initialState = Object.keys(conditions).reduce((acc, key) => {
        const { Component, ...rest } = conditions[key]

        acc[key] = rest

        return acc
    }, {})

    return initialState
}

const spreadObjectOnState = (obj, immerDraftBase) => {
    Object.entries(obj).forEach(([index, value]) => {
        if (typeof value === 'object' && value !== null) {
            spreadObjectOnState(value, immerDraftBase)
        } else {
            immerDraftBase[index] = value
        }
    })
}

const reducer = (state, { type, payload }) => produce(state, (draftState) => {
    const { condition, isComplete, ...rest } = payload
    switch (type) {
        case actions.CHANGE_COMPLETION_STATUS:
            draftState[payload.condition].isComplete = payload.isComplete

            draftState[payload.condition].payload = JSON.stringify(rest)

            spreadObjectOnState(rest, draftState[payload.condition])

            break

        default:
            throw new Error()
    }
})


const selectors = {
    canSubmit: (state) => {
        return Object.values(state).reduce((acc, val) => {
            if (acc === false) {
                return false
            }

            if (val.required && val.isComplete === false) {
                return false
            }

            return acc
        }, true)
    },
    payload: (state, conditions) => {
        const conditionKeys = Object.keys(conditions)

        return conditionKeys.reduce((conditions, condition) => {
            conditions[condition] = JSON.parse(state[condition]?.payload || '{}')

            return conditions
        }, {})
    },
}

const ConditionsForm = {
    types: {
        IS_SUBMITTING: 'IS_SUBMITTING',
        SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
        RESET: 'RESET',
        SUBMIT_SUCCEEDED: 'SUBMIT_SUCCEEDED',
    },
    creators: {
        setIsSubmitting: (isSubmitting) => ({
            type: ConditionsForm.types.IS_SUBMITTING,
            payload: {
                isSubmitting,
            }
        }),
        setErrorMessage: (errorMessage) => ({
            type: ConditionsForm.types.SET_ERROR_MESSAGE,
            payload: {
                errorMessage,
            }
        }),
        reset: (payload) => ({
            type: ConditionsForm.types.RESET,
            payload,
        }),
        setSubmitSucceeded: (payload) => ({
            type: ConditionsForm.types.SUBMIT_SUCCEEDED,
            payload,
        }),
    },
    initialState: {
        errorMessage: null,
        isSubmitting: false,
        submitSucceeded: false,
    },
    reducer: (state, { type, payload }) => produce(state, (draftState) => {
        switch (type) {
            case ConditionsForm.types.IS_SUBMITTING:
                draftState.isSubmitting = payload.isSubmitting
                break

            case ConditionsForm.types.SET_ERROR_MESSAGE:
                draftState.errorMessage = payload?.errorMessage
                break

            case ConditionsForm.types.SUBMIT_SUCCEEDED:
                draftState.submitSucceeded = true
                break

            case ConditionsForm.types.RESET:
                spreadObjectOnState(ConditionsForm.initialState, draftState)
                break

            default:
                throw new Error()
        }
    }),
    selectors: {
        isSubmitting: (state) => state?.isSubmitting,
        errorMessage: (state) => state?.errorMessage,
        submitSucceeded: (state) => state?.submitSucceeded,
    },
    mapStateToProps: (state) => ({
        isSubmitting: ConditionsForm.selectors.isSubmitting(state),
        errorMessage: ConditionsForm.selectors.errorMessage(state),
        submitSucceeded: ConditionsForm.selectors.submitSucceeded(state),
    }),
    mapDispatchToProps: (dispatch) => {
        return {
            setIsSubmitting: (payload) => dispatch(ConditionsForm.creators.setIsSubmitting(payload)),
            setErrorMessage: (payload) => dispatch(ConditionsForm.creators.setErrorMessage(payload)),
            setSubmitSucceeded: (payload) => dispatch(ConditionsForm.creators.setSubmitSucceeded(payload)),
            reset: (payload) => dispatch(ConditionsForm.creators.reset(payload)),
        }
    },
}

const useConnectedModule = (module, ownProps) => {
    const [
        state,
        dispatch,
    ] = useReducer(module.reducer, module.initialState)

    const defaults = {
        mapStateToProps: () => ({}),
        mapDispatchToProps: () => ({}),
        mergeProps: (
            stateProps,
            dispatchProps,
            ownProps
        ) => ({
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
        }),
    }

    const mapStateToProps = module.mapStateToProps || defaults.mapStateToProps
    const mapDispatchToProps = module.mapDispatchToProps || defaults.mapDispatchToProps
    const mergeProps = module.mergeProps || defaults.mergeProps

    const stateProps = mapStateToProps(state, ownProps)
    const dispatchProps = mapDispatchToProps(dispatch, ownProps)

    return mergeProps(
        stateProps,
        dispatchProps,
        ownProps,
    )
}

const ConditionAggregator = ({
    conditions,
    onSubmit,
    Submit,
    Form,
    postSubmit: globalPostSubmit,
    showLoadingOnSuccess = true,
}) => {
    const formRef = useRef(null)
    const conditionsForm = useConnectedModule(ConditionsForm)
    const [state, dispatch] = useReducer(reducer, conditions, init)

    const submitDisabled = !selectors.canSubmit(state) || conditionsForm.isSubmitting

    const sections = Object.entries(conditions).map(([key, { Component, requires = [] }]) => {
        return (
            <Component
                key={key}
                changeCompletionStatus={async (payload) => {
                    const requirements = await mapSeries(requires, () => state)
                    const requirementsSatisfied = requirements.every((requirement) => requirement === true)

                    if (!requirementsSatisfied) {
                        return false
                    }

                    dispatch({
                        type: actions.CHANGE_COMPLETION_STATUS,
                        payload: {
                            ...payload,
                            condition: key,
                        },
                    })
                }}
                isComplete={state[key]?.isComplete}
                submitConditionAggregator={() => {
                    if (!conditionsForm.isSubmitting) {
                        formRef?.current?.dispatchEvent(new Event('submit', {
                            cancelable: true
                        }))
                    }
                }}
                errorMessage={conditionsForm.errorMessage}
                stateProps={state[key]}
                payloads={selectors.payload(state, conditions)}
                conditionAggregatorState={state}
            />
        )
    })

    return (
        <Form formRef={formRef} onSubmit={async (event) => {
            event.preventDefault()
            if (conditionsForm.isSubmitting) {
                return null
            }

            conditionsForm.reset()

            try {
                conditionsForm.setIsSubmitting(true)
                const preSubmitHooks = Object.entries(conditions).filter(([key, { preSubmit }]) => !!preSubmit)
                const postSubmitHooks = Object.entries(conditions).filter(([key, { postSubmit }]) => !!postSubmit)

                const preSubmits = preSubmitHooks.map(([key, { preSubmit }]) => preSubmit)
                const postSubmits = postSubmitHooks.map(([key, { postSubmit }]) => postSubmit)

                const preSubmitHookKeys = preSubmitHooks.map(([key]) => key)
                const preSubmitHookValues = await mapSeries(preSubmits, () => ({
                    conditions,
                    state,
                    payloads: selectors.payload(state, conditions),
                }))

                const preSubmitHookResults = preSubmitHookKeys.reduce((obj, key, index) => ({ ...obj, [key]: preSubmitHookValues[index] }), {})

                const submitResults = await onSubmit(event, {
                    conditions,
                    state,
                    preSubmitHookResults,
                    payloads: selectors.payload(state, conditions),
                })

                const postSubmitHookResults = await mapSeries(postSubmits)

                await globalPostSubmit({
                    submitResults,
                    postSubmitHookResults,
                    payloads: selectors.payload(state, conditions),
                })

                conditionsForm.setIsSubmitting(false)
                conditionsForm.setSubmitSucceeded()
            } catch (error) {
                conditionsForm.setIsSubmitting(false)
                conditionsForm.setErrorMessage(error.message)
            }
        }}>
            {sections}
            {conditionsForm.submitSucceeded && showLoadingOnSuccess && <Loading />}
            {conditionsForm.submitSucceeded}
            {conditionsForm.errorMessage && (
                <div className="errorContainer">
                    {conditionsForm.errorMessage}
                </div>
            )}
            <Submit
                disabled={submitDisabled}
                isSubmitting={conditionsForm.isSubmitting}
                submitConditionAggregator={() => {
                    if (!conditionsForm.isSubmitting) {
                        formRef?.current?.dispatchEvent(new Event('submit', {
                            cancelable: true
                        }))
                    }
                }}
            />
        </Form>
    )
}

ConditionAggregator.defaultProps = {
    conditions: {},
    Submit: ({ disabled, formRef, isSubmitting }) => (
        <div className={isSubmitting ? 'loadingSpinner' : ''}>
            <input
                className='btn btn-primary btn-block'
                disabled={disabled}
                type='submit'
                value={isSubmitting ? '' : 'Submit'}
            />
        </div>
    ),
    Form: (({ children, onSubmit, formRef }) => (
        <form
            onSubmit={onSubmit}
            {...(formRef && { ref: formRef })}
        >
            {children}
        </form>
    )),
    onSubmit: () => { },
    showLoadingOnSuccess: true,
}

ConditionAggregator.propTypes = {
    conditions: PropTypes.object,
    Submit: PropTypes.func,
    Form: PropTypes.func,
    onSubmit: PropTypes.func,
    showLoadingOnSuccess: PropTypes.bool,
}

export default ConditionAggregator
