/* eslint-disable */
import React, { useEffect, useContext, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormSpy } from 'react-final-form'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Loading from 'piconetworks/pkg-loading'
import Account from 'modules/account'
import Loader from 'modules/loader'
import LandingPage from 'modules/landing-page'
import Links from 'modules/links'
import Me from 'modules/me'
import Metrics from 'modules/metrics'
import Newsletter from 'modules/newsletter'
import Blocks from 'modules/blocks'
import Wizard from 'modules/wizard'
import FeatureFlagsModule from 'modules/feature-flags'
import IframelyModule from 'modules/iframely'

import LoginLink from '../LoginLink/LoginLink';

import Markdown from 'wrappers/Markdown'
import getFields, { isCustomFieldsValid } from 'lib/CartHelper/getFields'
import ConditionAggregator from 'piconetworks/pkg-condition-aggregator'
import WizardSlider from 'piconetworks/pkg-wizard-slider'
import WizardRedux from 'pkgs/WizardRedux'
import AppContainerContext from '../AppContainer/AppContainerContext'

import styles from './style.module.scss'
import orderBy from 'lodash/orderBy'

const EnterEmail = ({
    authenticationMethods,
    currentUser,
    backButton = {},
    landingPage,
    isJoiningGroup,
    isPayingGroup,
    onAccountCreated,
    onLogin,
    onSubmitCallback,
    onLinkClick,
    onSkipOtp,
    setShouldSubmitForm,
}) => {
    const [authFlow, setAuthFlow] = useState(null)

    const dispatch = useDispatch()
    const linkColor = useSelector(Loader.selectors.linkColor)
    const isLoggedIn = !!useSelector(Loader.selectors.hasUserLoader)
    const isSignupLandingPage = landingPage?.type === 'signup_landing_page' || landingPage?.type === 'standard_page'
    const isPaymentLandingPage = landingPage?.type === 'payment_landing_page'
    const landingPageFields = useSelector(() => LandingPage.selectors.getMappedFields(landingPage?.fields))
    const blocks = useSelector((state) => Blocks.selectors.getBlocksByFormId(state, landingPage?.id))

    const socialLinksLoading = useSelector(Links.selectors.getLoading)
    const blocksLoading = useSelector(Blocks.selectors.getLoading)

    const smsSignupEnabled = useSelector(FeatureFlagsModule.selectors.smsSignup)
    const richMediaEnabled = useSelector(FeatureFlagsModule.selectors.iframelyRichMedia)

    const richMediaUrls = useSelector(richMediaEnabled ? IframelyModule.selectors.getAllRichMedia : () => { })

    const customFields = getFields(landingPageFields)

    const {
        isReturningUser,
        readyToPage,
    } = useContext(AppContainerContext)

    let customFieldsValid = (landingPageFields.length && !isReturningUser) ? false : true

    const allNewsletters = useSelector(Newsletter.selectors.allNewsletters)
    const landingPageNewsletters = landingPage?.newsletters || []

    const preSelectedNewsletters = landingPageNewsletters.map(({ id }) => id)
    const shouldRenderBackButton = backButton.shouldRender

    const { custom_text } = landingPage || {}

    const customTextURL = custom_text?.signup?.file_name
        || custom_text?.payment?.file_name
        // group signup text for billing admin / member
        || isJoiningGroup && custom_text?.group_member_signup?.file_name
        || isPayingGroup && custom_text?.billing_admin_signup?.file_name

    const isSmsUser = !!useSelector(Me.selectors.userPhone)
    const isEmailUser = !!useSelector(Me.selectors.userEmail)
    const isEmailShared = useSelector(Me.selectors.userEmailIsShared)
    const isPhoneShared = useSelector(Me.selectors.userPhoneIsShared)
    const shouldUpdateEmail = isSmsUser && (!isEmailUser || !isEmailShared)
    const oneTimeCodeSent = useSelector(Account.selectors.oneTimeCodeSent)
    const isNewRegistration = useSelector(Account.selectors.isNewRegistration)

    // due to rerendering issues with this component remounting,
    // this effect is made to only run once through the use of context `setReadyToPage`
    useEffect(() => {
        if (readyToPage) {
            if (!currentUser) {
                dispatch(Metrics.creators.sendMessage({
                    payload: {
                        promptType: landingPage?.type,
                        triggerId: landingPage?.id
                    }
                }))
            }
        }
    }, [landingPage, readyToPage])


    const onPasswordLogin = useCallback(({ selectedNewsletters, customProperties, company_slug }) => {
        const creator_username = company_slug.replace(/^@/, '')
        dispatch(
            Wizard.creators.updatePayload({
                payload: {
                    id: `LoginWizard_${creator_username}`,
                    payload: {
                        selectedNewsletters: selectedNewsletters,
                        customProperties: customProperties,
                    }
                }
            })
        )
    }, [dispatch])

    const emailPassWordSocialNewslettersLogin = getFields([{
        id: 'emailPassWordSocialNewslettersLogin',
        description: '',
        type: 'EmailPassWordSocialNewslettersLogin',
        required: false,
        order: 15,
    }])

    const textSignup = getFields([
        {
            id: 'textSignup',
            description: '',
            type: 'TextSignup',
            required: false,
            order: 15
        }
    ])

    const links = getFields([{
        id: 'link',
        title: 'Links',
        description: 'Custom Links',
        type: 'Link',
        required: false,
        order: 15,
    }])

    const renderBackButtonText = {
        title: 'Create an account',
        subtitle: `Continue below to ${isJoiningGroup
            ? 'join'
            : 'complete your payment'
            }.`,
    }

    const wizardSliderText = shouldRenderBackButton && !isSignupLandingPage
        ? renderBackButtonText
        : {}

    if (blocksLoading || socialLinksLoading) {
        return <Loading className={styles.loading} />
    }

    return (
        <div className={styles.enterEmail}>
            <WizardSlider
                title={wizardSliderText.title || ''}
                subtitle={wizardSliderText.subtitle || ''}
                wizardHeaderClass="signupFormHeader"
                wizardContentClass="signupFormContentFlex"
                onBackButtonClick={backButton.onClick}
                linkColor={linkColor}
                renderBackButton={shouldRenderBackButton}
                markDown={customTextURL && (
                    <Markdown url={customTextURL} className={cx(styles.markdown)} />
                )}
            >
                <WizardRedux.Step
                    onSubmit={({ values }) => {
                        const submitWizard = values?.wizardData?.submitWizard

                        if (!submitWizard) {
                            throw new Error('submitWizard not selected')
                        }

                        return {
                            submitWizard,
                        }
                    }}
                    rffProps={{
                        mutators: {
                            setWizardData: (args, state, utils) => {
                                utils.changeValue(state, 'wizardData', () => args[0])
                            },
                        },
                    }}
                    render={({
                        form,
                    }) => {
                        const Blocks = {
                            CustomFields: (block) => ({
                                required: true,
                                isComplete: customFieldsValid,
                                Component: ({ changeCompletionStatus }) => ((!isEmailShared || !isEmailUser) && (!oneTimeCodeSent || !authFlow)) ? (
                                    <Form
                                        onSubmit={() => { }}
                                        className={styles.contentBlock}
                                        render={() => !isReturningUser && !isEmpty(customFields) && (
                                            <div className={styles.customFields}>
                                                <FormSpy
                                                    onChange={(props) => {
                                                        customFieldsValid = isCustomFieldsValid(landingPageFields, props?.values)

                                                        if (customFieldsValid) {
                                                            changeCompletionStatus({
                                                                isComplete: true,
                                                                ...props?.values,
                                                            })
                                                        } else {
                                                            changeCompletionStatus({
                                                                isComplete: false,
                                                            })
                                                        }
                                                    }}
                                                />
                                                {emailPassWordSocialNewslettersLogin.map((Field, idx) => (
                                                    <Field
                                                        key={idx}
                                                        showError={false}
                                                        showTerms={false}
                                                        subtitle={block?.signup_block?.subtitle || ''}
                                                        title={block?.signup_block?.title || ''}
                                                    />
                                                ))}
                                                {customFields.map((Field, idx) => (
                                                    <Field
                                                        key={idx}
                                                        linkColor={linkColor}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    />
                                ) : null,
                            }),
                            Signup: (block) => ({
                                required: false,
                                isComplete: false,
                                // New users will be logged in immediately before entering OTP, but OTP field should
                                // remain on the screen until they refresh the page or enter OTP.
                                Component: ({ payloads }) => (
                                    (
                                        (!isEmailShared || !isEmailUser)
                                        || (oneTimeCodeSent)
                                    )
                                    && (authFlow === 'email' || !authFlow)
                                ) ? (
                                    <Form
                                        initialValues={{
                                            newsletters: preSelectedNewsletters,
                                        }}
                                        onSubmit={() => { return (<div />) }}
                                        render={() => (
                                            <div className={styles.signupContainer}>
                                                {emailPassWordSocialNewslettersLogin.map((Field, idx) => (
                                                    <Field
                                                        authFlow={authFlow}
                                                        key={idx}
                                                        className='block'
                                                        customProperties={payloads?.customFields}
                                                        popupId={landingPage?.id}
                                                        customFieldsValid={customFieldsValid}
                                                        isReturningUser={isReturningUser}
                                                        newsletters={orderBy(allNewsletters, ['newsletter_order'], ['asc'])}
                                                        authenticationMethods={authenticationMethods}
                                                        subtitle={isEmpty(customFields) && block?.signup_block?.subtitle || ''}
                                                        title={isEmpty(customFields) && block?.signup_block?.title || ''}
                                                        shouldUpdateEmail={shouldUpdateEmail}
                                                        showError={true}
                                                        showTerms={!oneTimeCodeSent}
                                                        onSubmitCallback={onSubmitCallback}
                                                        onAccountCreated={onAccountCreated}
                                                        onLogin={onLogin}
                                                        onPasswordLogin={onPasswordLogin}
                                                        onSkipOtp={onSkipOtp}
                                                        isPaymentLandingPage={isPaymentLandingPage}
                                                        setAuthFlow={setAuthFlow}
                                                        setShouldSubmitForm={setShouldSubmitForm}
                                                    >
                                                        {!shouldUpdateEmail && !oneTimeCodeSent && <LoginLink linkColor={linkColor} showLoginPrompt />}
                                                    </Field>
                                                ))}
                                            </div>
                                        )}
                                    />
                                ) : null
                            }),
                            SignupSMS: (block) => ({
                                required: false,
                                isComplete: false,
                                Component: () => (
                                    ((smsSignupEnabled && (!isSmsUser || !isPhoneShared))) && (authFlow === 'sms' || !authFlow)
                                ) ? (
                                    <Form
                                        onSubmit={() => {
                                            return <div />
                                        }}
                                        render={() => (
                                            <div
                                                className={
                                                    styles.signupContainer
                                                }
                                            >
                                                {textSignup.map(
                                                    (Field, idx) => (
                                                        <Field
                                                            key={idx}
                                                            isReturningUser={
                                                                isReturningUser
                                                            }
                                                            className='block'
                                                            authenticationMethods={authenticationMethods}
                                                            popupId={landingPage.id}
                                                            subtitle={block?.signup_block?.subtitle}
                                                            title={block?.signup_block?.title}
                                                            onSubmitCallback={onSubmitCallback}
                                                            onAccountCreated={onAccountCreated}
                                                            onLogin={onLogin}
                                                            setAuthFlow={setAuthFlow}
                                                            authFlow={authFlow}
                                                        ></Field>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    />
                                ) : null
                            }),
                            Link: (block) => ({
                                required: false,
                                isComplete: true,
                                Component: ({ }) => !isReturningUser && (
                                    <div>
                                        <Form
                                            onSubmit={(data) => { }}
                                            render={() => (
                                                <div>
                                                    {links.map((Field, idx) => (
                                                        <Field
                                                            key={idx}
                                                            linkBlocks={block ? [block?.link_block] : []}
                                                            linkColor={linkColor}
                                                            onLinkClink={onLinkClick}
                                                            richMediaData={richMediaUrls}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </div>
                                ),
                            }),
                            Text: (block) => ({
                                required: false,
                                isComplete: true,
                                required: false,
                                isComplete: true,
                                Component: ({ }) => !isReturningUser && (
                                    <div className={styles.markdownBlock}>
                                        <Markdown
                                            url={block?.text_block?.file_name}
                                            className={styles.markdown}
                                        />
                                    </div>
                                ),
                            }),
                        }

                        return (
                            <section className={styles.signupForm}>
                                <ConditionAggregator
                                    Submit={() => null}
                                    onSubmit={async (e, data) => {
                                        if (!isReturningUser && !data?.state?.customFields?.isComplete && !isLoggedIn) {
                                            throw new Error('Please complete all required fields.')
                                        }

                                        return {
                                            submitWizard: true,
                                        }
                                    }}
                                    postSubmit={(data) => {
                                        // advance to the next wizard step
                                        form.mutators.setWizardData(data.submitResults)
                                        form.submit()
                                    }}
                                    conditions={blocks.length
                                        ? blocks.reduce((conditions, block, idx) => {
                                            let condition = {}
                                            switch (block.type) {
                                                case 'signup':
                                                    if (block.signup_block.type === 'sms') {
                                                        condition = {
                                                            textSignup:
                                                                Blocks.SignupSMS(
                                                                    block
                                                                )
                                                        }
                                                    }
                                                    if (block.signup_block.type === 'email') {
                                                        condition = {
                                                            customFields: Blocks.CustomFields(block),
                                                            emailPassWordSocialNewslettersLogin:
                                                                Blocks.Signup(
                                                                    block
                                                                ),
                                                        }
                                                    }
                                                    break
                                                case 'text':
                                                    condition = {
                                                        [`text-${idx}`]: Blocks.Text(block),
                                                    }
                                                    break
                                                case 'link':
                                                    condition = {
                                                        [`link-${idx}`]: Blocks.Link(block),
                                                    }
                                                    break
                                            }
                                            return {
                                                ...conditions,
                                                ...condition,
                                            }
                                        }, {}) : {
                                            customFields: Blocks.CustomFields(),
                                            emailPassWordSocialNewslettersLogin: Blocks.Signup(),
                                            links: Blocks.Link(),
                                        }
                                    }
                                />
                            </section>
                        )
                    }}
                />
            </WizardSlider>
        </div>
    )
}

export default React.memo(EnterEmail)
